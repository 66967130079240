@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

a {
  color: #333;
}

/**
    Experimenting here a bit

    The first is for questions in radios etc. A short statement
    like "Are you an Alien?"
 */
.spb-form-lead-text {
  margin-top: 15px;
}

/**
  This is when you want a longer description. For an item like

  "Please enter the planet name of you _original_ species. Do not
   include other planets"
 */
.spb-form-info-text {
  font-style: italic;
  margin-bottom: 15px;
}

/**
 when you need to give yet another explanation
 */
.spb-form-explanation-text {
  font-style: italic;
  margin: 15px 0;
}

/**
   When you have a dynamic list of components
   where they can be deleted
 */
.spb-list-item {
  display: flex;
  align-items: center;
}

.spb-main-section-header {
  margin-top: 30px;
  font-weight: bold;
  font-size: 120%;
}

.spb-sub-section-header {
  margin-top: 15px;
  font-weight: bold;
}

/* Personalisation of Angular Material to show disable fields as normal */
input {
  --mdc-outlined-text-field-disabled-input-text-color: #000000ac;
}

* {
  --mdc-outlined-text-field-disabled-label-text-color: #000000ac !important;
}

.spb-no-flippers::-webkit-outer-spin-button,
.spb-no-flippers::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}